import { EventRequestMessageV1, Maybe } from "@/types"
import { isAxiosError } from "@/utils/error"

type ErrorResponse = {
  errors: { message: string; key: string }[]
}

/**
 * @param {String} url
 * @param {EventRequestMessageV1} data
 * @return {{method: string, url: string}|{method: string, url}}
 */
export default function (url: string, data: EventRequestMessageV1): { method: "get" | "post"; url: string } {
  /**
   * Simulate complete url for GET request
   * @return {string}
   */
  const completeUrl = () => {
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key])

    const params = encodeURIComponent(JSON.stringify(data))

    return `${url + (url.includes("?") ? "&message=" : "?message=") + params}`
  }

  const urlWithParams = completeUrl()
  return urlWithParams.length <= 2000 ? { method: "get", url: urlWithParams } : { method: "post", url }
}

/**
 *  checks if the error object has validation messages, parses it and returns the message to be displayed in debug-toolbar
 * @param error error object returned from server
 * @returns Array<string>|Object a string array of error messages
 */
export function parseError(error: unknown): Maybe<string[]> {
  if (!error) return undefined
  const isParseCandidate =
    isAxiosError<ErrorResponse>(error) && error.response && error.response.data && error.response.status === 400
  if (isParseCandidate) {
    const EMPTY_STR = ""
    const { errors } = error.response!.data
    if (errors && errors.length > 0) {
      return errors.map(error => {
        const message = error.message ? error.message : EMPTY_STR
        const key = error.key ? error.key : EMPTY_STR
        if (message || key) {
          return message.replace("Value", key)
        }
        return EMPTY_STR
      })
    }
    return [JSON.stringify(error)]
  }
  return [JSON.stringify(error)]
}
