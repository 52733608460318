import bus from "@/core/api/bus"
import logger from "@/core/logger"
import createRequestBuilder from "./request"
import { VIEW_PRODUCT } from "./event"

type ProductIdentifier = string | { productId: string; skuId?: string }

/**
 * Sends an event to Nosto when a recommended product is added to the cart.
 * @param product - The product identifier.
 * @param recoId - The recommendation identifier.
 */
export async function reportAddToCart(product: ProductIdentifier, recoId: string) {
  if (product && recoId) {
    const { productId, skuId } = typeof product === "string" ? { productId: product } : product
    await createRequestBuilder()
      .addEvent({ type: VIEW_PRODUCT, target: productId, targetFragment: skuId, ref: recoId })
      .load({ skipPageViews: true })
    bus.emit("addtocart", {
      productId,
      skuId,
      placementId: recoId
    })
    return
  }
  logger.info("No product or element info found.")
}
