import { EventRequestMessageV1 } from "@/lib"
import axios, { AxiosResponse } from "axios"
import handler from "./handler"

/**
 * Performs a post request which matches the specifications simple requests format to avoid preflight requests.
 * For this reason content-type is fixed to text/plain.
 * @param url request url
 * @param data request body
 * @return {Promise}
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
 */
export default function xdr(url: string, data: EventRequestMessageV1): Promise<AxiosResponse> {
  const handlerResult = handler(url, data)
  return axios({
    url: handlerResult.url,
    data,
    method: handlerResult.method,
    headers: { "Content-Type": "text/plain" }
  })
}
