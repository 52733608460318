import windows from "@/core/windows"
import { ResponseData } from "../requests/fetchPopupData"
import { cloneScript, isExtractableScript } from "@/utils/script"
import { transpileCSS } from "@/utils/cssNesting"

export function loadResponseDataScripts(responseData: ResponseData) {
  const { site: siteWindow, nosto: nostoWindow } = windows
  // Add contents to dialog and run script in context of iframe.
  let responseHtmlNode = siteWindow.document.createElement("div")
  responseHtmlNode.innerHTML = responseData.html
  if (responseHtmlNode.childNodes.length === 1) {
    // responseData.html is wrapped into div, so we expect to get one node only.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    responseHtmlNode = responseHtmlNode.childNodes[0] as HTMLDivElement
  }
  const scripts = Array.from(responseHtmlNode.querySelectorAll("script")).filter(isExtractableScript)
  scripts.forEach(s => s.remove()) // remove scripts from html we appending to main page
  siteWindow.document.body.append(responseHtmlNode) // append html/css to main page
  const dialog = responseHtmlNode
  scripts.forEach(script => {
    const newScript = cloneScript(nostoWindow.document, script)
    nostoWindow.document.body.append(newScript) // append scripts to iframe - to execute scripts in iframe context.
  })

  // recreate module scripts
  const moduleScripts = Array.from(responseHtmlNode.querySelectorAll<HTMLScriptElement>("script[type='module']"))
  moduleScripts.forEach(script => {
    script.replaceWith(cloneScript(siteWindow.document, script))
  })

  // clean CSS
  transpileCSS(dialog)

  return dialog
}
