let cssNestingSupport: boolean | undefined

/**
 * Checks if CSS nesting is supported in the current browser environment.
 */
export function cssNestingSupported(force?: boolean) {
  if (cssNestingSupport === undefined || force) {
    try {
      // requires https://caniuse.com/mdn-api_cssstylesheet_cssstylesheet
      const sheet = new CSSStyleSheet()
      // requires https://caniuse.com/css-nesting
      sheet.insertRule("div { color: red; & > span { color: blue; }; .child { color: green; } }")
      cssNestingSupport = sheet.cssRules[0].cssRules.length === 2
    } catch (e) {
      cssNestingSupport = false
    }
  }
  return cssNestingSupport
}

/**
 * Transpiles nested CSS usage.
 */
export function transpileNestedCSS(cssText: string) {
  // TODO temporary dummy
  return cssText
}

function getStyleElements(element: HTMLElement): HTMLStyleElement[] {
  if (element.matches && element.matches("style")) {
    return [element as HTMLStyleElement]
  } else if (typeof element.querySelectorAll === "function") {
    return Array.from(element.querySelectorAll<HTMLStyleElement>("style"))
  }
  return []
}

/**
 * Transpile the CSS contained in give elements.
 */
export function transpileCSS(...elements: HTMLElement[]) {
  if (cssNestingSupported()) {
    return // no need to transpile
  }
  elements.flatMap(getStyleElements).forEach(element => {
    // TEMP style[nested] is a temporary attribute to indicate nested css
    if (element.textContent && element.hasAttribute("nested")) {
      element.textContent = transpileNestedCSS(element.textContent)
      element.dataset.transpiled = "true"
    }
  })
}
