import { deepClone } from "@/utils/deepClone"
import createAttribution from "@/core/api/event/attribution"
import createAction from "./action"
import { Session } from "./types"
import { RenderMode } from "@/types"
import { TaggingData } from "@/core/tagging/types"

function createSession(): Session {
  const data: TaggingData = {
    customer: undefined,
    variation: undefined,
    restoreLink: undefined,
    cart: undefined,
    // action scope
    // (for data that is scoped to individual pages or even smaller scopes)
    products: [],
    order: undefined,
    searchTerms: undefined,
    categories: undefined,
    categoryIds: undefined,
    parentCategoryIds: undefined,
    tags: undefined,
    customFields: undefined,
    affinitySignals: undefined,
    elements: undefined,
    pageType: undefined,
    sortOrder: undefined,
    pluginVersion: undefined
  }

  let responseMode: RenderMode = "JSON_ORIGINAL"

  function newAction() {
    return createAction(deepClone(data), responseMode)
  }

  return {
    setCart(cart) {
      data.cart = cart
      return this
    },
    setCustomer(customer) {
      data.customer = customer
      return this
    },
    setVariation(variation) {
      data.variation = variation
      return this
    },
    setRestoreLink(restoreLink) {
      data.restoreLink = restoreLink
      return this
    },
    setResponseMode(mode) {
      responseMode = mode
      return this
    },
    viewFrontPage() {
      return newAction().setPageType("front")
    },
    viewCart() {
      return newAction().setPageType("cart")
    },
    viewNotFound() {
      return newAction().setPageType("notfound")
    },
    viewProduct(product) {
      return newAction().setPageType("product").setProduct(product)
    },
    viewProductSku(productId, skuId) {
      return newAction().setPageType("product").setProduct({ product_id: productId, selected_sku_id: skuId })
    },
    viewCategory(...categories) {
      return newAction().setPageType("category").setCategories(categories)
    },
    viewTag(...tags) {
      return newAction().setTags(tags)
    },
    viewCustomField(customFields) {
      return newAction().setCustomFields(customFields)
    },
    viewSearch(...searchTerms) {
      return newAction().setPageType("search").setSearchTerms(searchTerms)
    },
    viewOther() {
      return newAction()
    },
    addOrder(order) {
      return newAction().setPageType("order").setOrder(order)
    },
    reportAddToCart(product, element) {
      return newAction().setRef(product, element)
    },
    /**
     * @deprecated use {@link api.recordAttribution(Event) } instead
     * @param type {@link EventType} value
     * @param target target object id
     * @param ref the slotId of the recommendation
     * @param refSrc the ID of the source object that contained the ref
     * @returns
     */
    recordAttribution(type, target, ref, refSrc) {
      return createAttribution().recordAttribution({ type, target, ref, refSrc })
    }
  }
}

export default createSession
export const sessionInstance = createSession()
