import measurePerformance from "@/core/measurePerformance"
import type { Loaders } from "@/core/loaders"
import logger from "@/core/logger"
import { cloneScript, isExtractableScript } from "@/utils/script"
import { transpileCSS } from "@/utils/cssNesting"

function extractScripts(doc: Document, domElement: ParentNode) {
  const scripts = Array.from(domElement.querySelectorAll("script")).filter(isExtractableScript)
  scripts.forEach(script => script.remove())

  // recreate module scripts
  const moduleScripts = Array.from(domElement.querySelectorAll<HTMLScriptElement>("script[type='module']"))
  moduleScripts.forEach(script => {
    script.replaceWith(cloneScript(doc, script))
  })

  return scripts
}

/**
 * Promise execution wrapper to consume errors
 */
async function consumePromise(promise: Promise<void>) {
  try {
    await promise
  } catch (e) {
    logger.debug(e)
  }
}

function evaluateJS(scripts: HTMLScriptElement[], loaders: Loaders, divId?: string) {
  return consumePromise(
    measurePerformance("nosto.evaluate_js", () => {
      return new Promise<void>((resolve, reject) => {
        function exec() {
          if (scripts.length > 0) {
            try {
              loaders.evalScript(scripts.shift()!, exec, divId || "")
            } catch (e) {
              reject(e)
            }
          } else {
            resolve()
          }
        }

        exec()
      })
    })
  )
}

const ep = Element.prototype

const operationMapping = {
  REPLACE: ep.replaceWith,
  APPEND: ep.after,
  PREPEND: ep.before,
  INSERT_INTO: ep.append,
  INSERT_AFTER_BEGIN: ep.prepend
} as const

type DomOperation = typeof ep.replaceWith
type InsertMode = keyof typeof operationMapping

function invalidInjection(mode: string) {
  throw new Error(`Invalid injection mode ${mode}`)
}

function domOperation(mode: InsertMode): DomOperation {
  return operationMapping[mode] || invalidInjection(mode)
}

function cleanScripts(doc: Document, elements: HTMLElement[]) {
  // create document fragment
  const df = doc.createDocumentFragment()
  df.append(...elements)
  // to extract scripts in one go
  const scripts = extractScripts(doc, df)
  const children = df.childNodes
  // synchronize dom array (remove scripts)
  elements.length = children.length
  children.forEach((e, i) => (elements[i] = e))
  return scripts
}

export default function createSelector(doc: Document, loaders: Loaders) {
  function normalize(content: string | HTMLElement) {
    if (typeof content === "string") {
      const div = doc.createElement("div")
      div.innerHTML = content.trim()
      return Array.from(div.childNodes)
    }
    return [content]
  }

  function html(container: HTMLElement, htmlText: string) {
    const elements = normalize(htmlText)
    transpileCSS(...elements)
    container.replaceChildren(...elements)
    return evaluateJS(extractScripts(doc, container), loaders, container.id)
  }

  function performOperation(mode: InsertMode, target: HTMLElement, content: string | HTMLElement, divId?: string) {
    return performDomOperation(mode, target, normalize(content), divId)
  }

  function performDomOperation(mode: InsertMode, target: HTMLElement, elements: HTMLElement[], divId?: string) {
    const scripts = cleanScripts(doc, elements)
    transpileCSS(...elements)
    domOperation(mode).apply(target, elements)
    return evaluateJS(scripts, loaders, divId)
  }

  return {
    html,
    performOperation,
    performDomOperation
  }
}

export type Selector = ReturnType<typeof createSelector>

/**
 * to be used with template literals to perform css escaping for user-provided parts
 * css`#${divId}`
 */
export function cssEscape(arr: TemplateStringsArray, ...items: unknown[]) {
  return items.map((e, i) => arr[i] + CSS.escape(String(e))).join("") + arr[arr.length - 1]
}
