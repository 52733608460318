import placements from "@/placements"
import visit from "@/core/visit"
import logger from "@/core/logger"
import context, { Context } from "@/core/context"
import { findForcedSegments } from "@/core/tagging/extract"
import createRequestBuilder, { RequestBuilder } from "@/core/request"
import { activateOverlay, popupCampaigns, getOverlay, openPopup, enablePopup, disablePopup } from "@/overlay"
import { setPlacementsCallback } from "@/placements/async/placementQueue"
import * as injection from "@/placements/injection"
import settings, { modifySettings, Settings } from "@/core/settings"
import { reportAddToCart } from "@/core/minicart"
import { reportCouponGiven as couponGiven } from "@/core/coupon"
import { setCustomer } from "@/core/loggedin"
import { addSegment as addSegmentCodeToVisit } from "@/core/segments"
import { addAffinitySignals } from "@/core/affinity"
import { getCustomAffinities } from "@/core/customAffinities"
import { setTaggingProvider, pageTagging } from "@/core/tagging"
import sendTagging from "@/core/tagging/resendAll"
import createSession, { sessionInstance } from "@/session/session"
import { resendCartTagging, resendCustomerTagging } from "@/core/platform"
import { recordSearch, recordSearchSubmit } from "@/search/analytics/impression"
import { recordSearchClick } from "@/search/analytics/click"
import search from "@/search/search"
import getSearchSessionParams from "@/search/sessionParams"
import bus, { EventMapping } from "./bus"
import { InsertMode, Maybe, PushedCustomer, PushedProduct, EventRequestMessageV1 } from "@/types"
import { Cart } from "@/core/tagging/types"
import { CART_POPUP_RECOMMENDATIONS, Event } from "../event"
import createAttribution from "./event/attribution"
import getCurrencyFormats from "@/search/currencyFormats"
import { isAutoLoad, setAutoLoad } from "./autoload"
import { Level } from "../logger/types"
import { setExperiments } from "@/core/abtesting"
import { Overlay } from "@/overlay/overlay"

let loaded = false
let recommendationsEnabled = true

function captureError(error: unknown, reporter: string, level: Level = "warn") {
  logger[level](reporter, error)
}

function customer(customer: PushedCustomer) {
  // for public API calls, we are explicitly setting the source
  customer.source = "api"
  return setCustomer(customer)
}

type Install = {
  context: Context
  settings: Settings
  overlay: Overlay
}

function install(callbackFn: (cb: Install) => void) {
  callbackFn({
    context,
    settings,
    overlay: getOverlay()
  })
}

function getSettings() {
  return settings
}

function addPageTaggingToRequest(request: RequestBuilder, sendElements: boolean, unwrappedReference?: string) {
  const params = {
    data: pageTagging(),
    forcedSegments: findForcedSegments() || []
  }

  if (sendElements) {
    setPlacementsCallback(elements => {
      request.setElements(elements)
      void request.send({ skipEvents: true })
    })
    params.data.elements = placements.getPlacements()
  } else {
    params.data.elements = []
  }

  request.populateFrom(params, unwrappedReference)
}

function removeCampaigns(divIds: string[]) {
  divIds.forEach(placements.removeContent)
}

function showPlacementPreviews(placement: { element: HTMLElement; mode: InsertMode }, content: string | HTMLElement) {
  void injection.updateElement(placement.element, placement.mode, content)
}

function defaultSession() {
  return sessionInstance
}

function createRecommendationRequest(flags?: { includeTagging?: boolean; state?: EventRequestMessageV1 }) {
  const request = createRequestBuilder(flags?.state)
  if (flags?.includeTagging) {
    addPageTaggingToRequest(request, recommendationsEnabled)
  }
  return request
}

function setRecommendationsEnabled(flag: boolean) {
  recommendationsEnabled = flag
}

function listen<T extends keyof EventMapping>(phase: T, callback: (...args: EventMapping[T]) => void) {
  bus.on(phase, callback)
}

function load() {
  if (!loaded) {
    loaded = true
    return loadRecommendations()
  } else {
    return Promise.resolve()
  }
}

function loadRecommendations(element?: string | { markNostoElementClicked: string }) {
  const request = createRequestBuilder()
  const sendElements = recommendationsEnabled
  let unwrappedReference: Maybe<string> = undefined
  if (typeof element === "string") {
    unwrappedReference = element
  } else if (typeof element === "object") {
    unwrappedReference = element.markNostoElementClicked
  }
  addPageTaggingToRequest(request, sendElements, unwrappedReference)
  return request.loadRecommendations()
}

function loadCartPopupRecommendations(products: PushedProduct[], cart: Cart, alwaysShow: boolean) {
  const request = createRequestBuilder()
  addPageTaggingToRequest(request, false)

  if (products && products[0] && products[0].product_id) {
    request.addEvent({ type: CART_POPUP_RECOMMENDATIONS, target: products[0].product_id })
  }

  request.setCartContent(cart)
  return request.loadCartPopupRecommendations(alwaysShow)
}

async function resendCartContent(cart: Cart) {
  if (cart && cart.items) {
    await createRequestBuilder().setCartContent(cart).send({ skipPageViews: true })
    bus.emit("carttaggingresent", { cart_items: cart.items })
    return
  }
  logger.info("No cart info found.")
}

/**
 * API for recording attribution without triggering an ev1 request
 * @param event the #Event object
 */
function recordAttribution(event: Event) {
  return createAttribution().recordAttribution(event)
}

function noop() {}

/**
 * Main API object that is exposed to the client script. This object contains all the public methods
 * that can be used to interact with the Nosto API.
 *
 * The main way to interact with the API is to use the nostojs function. This function receives a callback
 * function as a parameter and executes it with the API object as a parameter.
 *
 * @group Core
 */
const api = {
  /** @hidden */
  internal: {
    logger,
    setTaggingProvider,
    getSettings,
    modifySettings,
    getOverlay,
    activateOverlay,
    context,
    getCurrencyFormats,
    couponGiven,
    getCustomAffinities
  },
  // TODO docs
  placements,
  // TODO docs
  visit,
  /**
   * @deprecated since this was a quick hack for usage in Codepen.IO
   * @hidden
   */
  setResponseMode: noop,
  /**
   * API method create a new session. This should be used when you might want to
   * have multiple sessions on the same page. In most cases, using
   * @see {@link defaultSession} will suffice.
   *
   * @deprecated
   * @hidden
   * @return {Session} the newly created session
   */
  createSession,
  /**
   * API method to access the default session. This should only be used when implementing
   * Nosto on a single-page application atop a framework such as React, Vue, Angular or
   * the likes.
   * <br/><br/>
   * If you are not using a single-page application but require programmatic access to the
   * Nosto request builder use {@link createRecommendationRequest}.
   *
   * @return {Session} the instance of the default session
   */
  defaultSession,
  /**
   * API method to create a recommendation request. This should only be used when you
   * require programmatic access to the Nosto request builder.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and  you are implementing Nosto, you must use the {@link defaultSession}
   * method.
   *
   * @param {Object} flags a set of flags to customise to request behaviour (eg. {"includeTagging":true}
   * to initialise the request from the page tagging.
   * @return {RequestBuilder} the instance of the request.
   */
  createRecommendationRequest,
  /**
   * API method to disable the automatic initialization of Nosto. This should be used in
   * cases when you want to manually load content.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you must disable
   * auto-loading.
   *
   * @param {Boolean} flag a true or false value indicating whether to automatically load or not
   *
   * @example
   * nostojs(api => api.setAutoLoad(false))
   * nostojs(api => api.load())
   */
  setAutoLoad,
  /**
   * API method to check the status of the autoload flag. This should be used for debugging
   * purposes only.
   *
   * @deprecated since it served little or no purpose and clutters the API erasure
   * @hidden
   * @return {Boolean}
   */
  isAutoLoad,
  /**
   * @deprecated
   * @hidden
   * @param {Boolean} flag a true or false value indicating whether to disable placements or not
   */
  setRecommendationsEnabled,
  /**
   * API method to register a listener for JS API events. Nosto's JS API dispatches
   * multiple events across the session lifetime.
   * <br/><br/>
   * Due to the wide gamut of events dispatched, listing them all is still a work in
   * progress.
   *
   * @param {String} phase
   * @param {Function} cb the callback function to be invoked
   *
   * @example
   * <caption>to log a message whenever a request is made to Nosto</caption>
   * nostojs(api => api.listen('taggingsent'), () => console.log("The tagging was sent"));
   */
  listen,
  /**
   * API method to reload all onsite recommendations and content. This should only be used when need to
   * reload all recommendations and content e.g. on a overlay modal.
   * <br/><br/>
   * Incorrect or extraneous usage of this method will lead to skewed page-view
   * statistics, ad every invocation of this method results in a +1 page-view count.
   *
   * @return {Promise}
   */
  loadRecommendations,
  /**
   * API method to load Nosto. This function is automatically invoked when the page loads.
   *
   * @return {Promise}
   *
   * @example
   * <caption>to manually load recommendations after DOM ready</caption>
   * nostojs(api => api.load());
   */
  load,
  /**
   * API method that to debug the state the page tagging. This is useful for debugging
   * what Nosto sees. You are able to see all the page tagging via the debug toolbar.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, if used, this method will always return
   * an empty object (as there shouldn't be any tagging/metadata on the page).
   * <br/><br/>
   * This is only for debugging purposes and should never be used in a production environment
   *
   * @return {Object} the representation of the page tagging
   *
   * @example
   * <caption>to log the page state to the console</caption>
   * nostojs(api => console.log(api.pageTagging()));
   */
  pageTagging,
  /** @hidden */
  loadCartPopupRecommendations,
  /**
   * Sends an event to Nosto when a recommended product is added to the cart.
   *
   * @param productId
   * @param nostoElementId
   * @return {Promise<Object>}
   */
  reportAddToCart,
  /** @hidden */
  captureError,
  /**
   * @hidden
   * @deprecated
   */
  recommendedProductAddedToCart: reportAddToCart,
  /**
   * API method to force the current session to be a part of the given experiment.
   *
   * @deprecated since no one knows what goes in here.
   * @hidden
   * @param experiments the experiments to move the session into
   * @return {Promise<Object>}
   */
  experiments: setExperiments,
  /**
   * API method to resend the provided customer details to Nosto. This is used in situations
   * when the customer details is loaded after the client script initialization.
   * <br/><br/>
   * If the current customer is not provided, you will not be able to leverage features such as
   * triggered emails. While it is recommended to always provide the details of
   * the currently logged in customer, it may be omitted if there are concerns
   * about privacy or compliance.
   * <br/><br/>
   * It is not recommended to pass the current customer details to the request
   * builder but rather use the customer tagging.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCustomer}
   * to provide the customer information.
   * <br/><br/>
   * This method is legacy method and therefore named incorrectly. Is the customer equivalent
   * of the resendCartContent method and actually should be called resendCustomerDetails.
   *
   * @param {Customer} customer the details of the currently logged in customer
   * @return {Promise<Object>}
   *
   * @example
   * nostojs(api => api.customer({
   *   first_name: "Mridang",
   *   last_name: "Agarwalla",
   *   email: "mridang@nosto.com",
   *   newsletter: false,
   *   customer_reference: "5e3d4a9c-cf58-11ea-87d0-0242ac130003"
   * }))
   */
  customer,
  /** @hidden */
  popupCampaigns,
  /** @hidden */
  reloadOverlay: noop,
  /** @hidden */
  openPopup,
  /** @hidden */
  enablePopup,
  /** @hidden */
  disablePopup,
  /**
   * API method to resend the cart content to Nosto. This is used in situations
   * when the cart tagging is loaded after the client script initialization.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCart}
   * to provide the cart information.
   *
   * @param {Cart} cart content of the cart
   * @return {Promise<Object>}
   *
   * @example
   * nostojs(api => api.resendCartContent({
   *   items: [
   *     product_id: "101",
   *     sku_id: "101-S",
   *     name: "Shoe",
   *     unit_price: 34.99
   *     price_currency_code: "EUR"
   *   ]
   * }))
   */
  resendCartContent,
  /**
   * API method to resend the cart tagging to Nosto. This is used in situations
   * when the cart tagging is loaded after the client script initialization. This method
   * reads all metadata having the class "nosto_cart" and sends the extracted cart
   * information to Nosto.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCart}
   * to provide the cart information.
   *
   * @return {Promise<Object>}
   *
   * @example
   * nostojs(api => api.resendCartTagging())
   */
  resendCartTagging,
  /**
   * API method to resend the customer tagging to Nosto. This is used in situations
   * when the customer tagging is loaded after the client script initialization. This method
   * reads all metadata having the class "nosto_customer" and sends the extracted customer
   * information to Nosto.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCustomer}
   * to provide the customer information.
   *
   * @return {Promise<Object>}
   *
   * @example
   * nostojs(api => api.resendCustomerTagging())
   */
  resendCustomerTagging,
  /**
   * API method to resend all the tagging to Nosto. This is used in situations when
   * the cart and the customer tagging is loaded after the client script initialization.
   *
   * While you can use resendCartTagging and the resendCustomerTagging to achieve the
   * same - this method will make a single request to Nosto.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern.
   *
   * @return {Promise<Object>}
   *
   * @example
   * nostojs(api => api.sendTagging())
   */
  sendTagging,
  /**
   * API method to manually add a given segment code to the the current user.  This
   * is used in situations when you want to segment users based on external logic.
   * <br/><br/>
   * Sending a segment code does not automatically create the corresponding segment.
   *
   * @example
   * <caption>to add a user to segment when they've used a discount code</caption>
   * nostojs(api => api.addSegmentCodeToVisit('discount code user'))
   */
  addSegmentCodeToVisit,
  /**
   * Manually specify user affinity signals.
   * This is useful for features like user quizzes or surveys where they would specify their preferences.
   * <br/><br/>
   * Explicit affinity signals contribute to the final affinities in a weighted system, and
   * they are weighted lower than buying an item, but higher than adding it to cart.
   *
   * @example
   * <caption>to add affinity to specific brands</caption>
   * nostojs(api => api.addAffinitySignals({ brand: ['nike', 'adidas'] }))
   */
  addAffinitySignals,
  /**
   * Removes injected content from the supplied divIds
   * If campaign was injected statically, then static placement just clears its contents.
   * If dynamically, the injected element gets removed from DOM
   * @param {String[]} divIds
   */
  removeCampaigns,
  /**
   * @deprecated since this is for debug-toolbar usage only and should not be in the public API
   * @hidden
   * @param placement
   * @param content
   */
  showPlacementPreviews,
  /**
   * @deprecated since this is for debug-toolbar usage only and should not be in the public API
   * @hidden
   * @param callbackFn
   */
  install,
  /**
   * API method to retrieve search affinities and segments and transform it to partial search query.
   * <br/><br/>
   * Results are cached to sessionStorage and is refreshed after cacheRefreshInterval
   *
   * @param {SearchSessionParamsOptions} options
   * @returns {Promise<SearchSessionParams>}
   *
   * @example
   * nostojs(api => api.getSearchSessionParams({ maxWait: 2000, cacheRefreshInterval: 60000 }).then((sessionParams) => sessionParams))
   */
  getSearchSessionParams,
  /**
   * Search function which requests graphql search endpoint.
   *
   * @param {SearchQuery} query Search query.
   * @param {SearchOptions=} options Search custom options.
   * @returns {Promise<SearchResult>}
   *
   * @example
   * nostojs(api => {
   *  api.search({
   *    query: 'green',
   *    products: {
   *     fields: ['name', 'customFields.key', 'customFields.value']
   *    }
   *  })
   *    .then(response => response)
   *    .catch(err => err)
   *  })
   * })
   */
  search,
  /**
   * Record search event, should be send on any search
   *
   * @param {SearchTrackOptions} type search type
   * @param {SearchQuery} query Full API query
   * @param {SearchResult} response {object} Full API response
   */
  recordSearch,
  /**
   * Record search click event
   *
   * @param {SearchTrackOptions} type search type
   * @param {object} hit Full hit object from search API
   */
  recordSearchClick,
  /**
   * Record search submit event (e.g. search form submit). Required to track organic searches.
   *
   * @param {string} query Search query
   */
  recordSearchSubmit,
  recordAttribution
}

export default api

/**
 * @group Core
 * @interface
 * */
export type API = typeof api
