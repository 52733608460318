import { EventResponseMessage, Maybe } from "@/types"
import windows from "./windows"
import bus from "./api/bus"

type GoogleAnalytics4 = (action: string, eventName: string, eventData: { segment_id: string }) => void

interface UniversalAnalytics {
  (
    send: string,
    action: string,
    eventData: { nonInteraction: boolean; eventCategory: string; eventAction: string }
  ): void
  getAll: () => Tracker[]
}

interface Tracker {
  get: (name: string) => string
}

function nostoGetGA(target: Window): Maybe<UniversalAnalytics> {
  // @ts-expect-error unknown field
  return target[target.GoogleAnalyticsObject || "ga"]
}

function nostoGetGA4(target: Window): Maybe<GoogleAnalytics4> {
  // @ts-expect-error unknown field
  return target.gtag
}

export function sendSegments(data: EventResponseMessage) {
  const googleAnalytics = data ? data.ga : undefined
  if (!googleAnalytics || !googleAnalytics.s) {
    return
  }

  const ga = nostoGetGA(windows.nosto) || nostoGetGA(windows.site)
  const ga4 = nostoGetGA4(windows.nosto) || nostoGetGA4(windows.site)
  const segments = googleAnalytics.s
  const eventName = "nostoSegment"

  if (typeof ga4 === "function") {
    segments.forEach(segment =>
      ga4("event", eventName, {
        segment_id: segment
      })
    )
    // Fallback to Universal analytics in case if GA4 is not available
  } else if (typeof ga === "function" && typeof ga.getAll === "function") {
    const trackers = ga.getAll()
    trackers.forEach(tracker => {
      const send = `${tracker.get("name")}.send`
      for (let i = 0; i < segments.length; i += 1) {
        ga(send, "event", {
          nonInteraction: true,
          eventCategory: eventName,
          eventAction: segments[i]
        })
      }
    })
  }
}

export function initGoogleAnalytics() {
  bus.on("taggingsent", sendSegments)
}
